import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="http://ericzeffery.com/">
        Eric Zeffery
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const tiers = [
  {
    title: 'About Me',
    description: [
      'Husband/Father',
      'Baseball Coach',
      'Fitness Nut',
      'Growth Mindset',
    ],
    href: 'https://www.linkedin.com/in/ericzeffery/',
    buttonText: 'BIO',
    buttonVariant: 'outlined',
  },
  {
    title: 'Resume',
    description: [
      'Strategist',
      'Team Leader',
      'Results Driven',
      'Mobile First',
    ],
    href: 'https://drive.google.com/file/d/1j1k3QAqYbG95sClVp0B-b1eTIWz_bBEY/view?usp=sharing',
    buttonText: 'PDF file',
    buttonVariant: 'outlined',
  },
  {
    title: 'Projects',
    description: [
      'Fireable.io',
      'Kiddo Coins',
      'PinBall',
      'The Walker',
    ],
    href: 'https://github.com/EricZeff',
    buttonText: 'Github',
    buttonVariant: 'outlined',
  }
];

const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: [
      'Cool stuff',
      'Random feature',
      'Team feature',
      'Developer stuff',
      'Another one',
    ],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];

function ProfileContent() {
  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <AppBar
        position="static"
        color="default"
        elevation={0}
        sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Typography variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Eric Zeffery
          </Typography>
          <nav>
            <Link
              variant="button"
              color="text.primary"
              href="https://www.linkedin.com/in/ericzeffery/"
              sx={{ my: 1, mx: 1.5 }}
            >
              Bio
            </Link>
            <Link
              variant="button"
              color="text.primary"
              href="https://github.com/EricZeff"
              sx={{ my: 1, mx: 1.5 }}
            >
              GitHub
            </Link>
          </nav>
          <Button href="#" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
            Login
          </Button>
        </Toolbar>
      </AppBar>
      {/* Hero unit */}
      <Container disableGutters maxWidth="sm" component="main" sx={{ pt: 8, pb: 6 }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Director, Software Engineering
        </Typography>
        <Typography variant="h5" align="center" color="text.secondary" component="p">
          Managing software engineering teams to drive success for companies through servant leadership. 
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container component="main" align="center" >
        <Grid container spacing={1} align="center">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid
              item
              key={tier.title}
              md={4}
            >
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{
                    align: 'center',
                  }}
                  sx={{
                    align: 'center',
                    backgroundColor: (theme) =>
                      theme.palette.mode === 'light'
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: 'center',
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      mb: 2,
                    }}
                  >
                    <Typography component="h2" variant="h3" color="text.primary">
                    </Typography>
                    <Typography variant="h6" color="text.secondary">
                    </Typography>
                  </Box>
                  <ul>
                    {tier.description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} href={tier.href}>
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
 
    </React.Fragment>
  );
}

export default function Profile() {
  return <ProfileContent />;
}